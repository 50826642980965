.screen-loading-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #fff;
}

.screen-loading-container .loading-container {
  position: fixed;
  width: 200px;
  height: 200px;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.screen-loading-container .message-delay {
  position: absolute;
  width: 100%;
  height: 200px;
  margin: auto;
  top: 75%;
  text-align: center;
  padding: 25px;
}

.screen-loading-container .message-tier {
  position: absolute;
  width: 100%;
  margin: auto;
  text-align: center;
}

.screen-loading-container .loading-container .item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid #5422CC;
  border-radius: 50%;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation: spin 2s ease infinite;
}

.screen-loading-container .loading-container .item:nth-child(1) {
  width: 50px;
  height: 50px;
}

.screen-loading-container .loading-container .item:nth-child(2) {
  width: 100px;
  height: 100px;
  animation-delay: 0.1s;
}

.screen-loading-container .loading-container .item:nth-child(3) {
  width: 150px;
  height: 150px;
  animation-delay: 0.2s;
}

.screen-loading-container .loading-container .item:nth-child(4) {
  width: 200px;
  height: 200px;
  animation-delay: 0.3s;
}

@keyframes spin {
  50% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}