.Modal {
  position: fixed;
  z-index: 2000;
}

.overlay {
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal__container {
  position: relative;
}