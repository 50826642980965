* {
  margin: 0;
  padding: 0;
}

html, body, #root {
  box-sizing: border-box;
  height: 100%;
}

.react-datepicker-wrapper{
  display: block !important
}

html * {
  box-sizing: inherit;
}

body {
  margin: 0;
  min-height: 100vh;
}

.show > .dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: unset !important;
  color: #767676 !important;
  outline: unset !important;
}

.text-justify {
  text-align: justify;
}

.btn.btn-link:focus,
.btn.btn-link:focus-visible {
  box-shadow: unset;
  outline: 0;
}

.wantText {
  margin-top: 10px;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

._3Tv81 {
  flex-direction: column !important;
  width: auto !important;
  height: 730px !important;
  margin-top: 80px !important;
}

._2NVM0{
  position: relative !important;
  height: 200px !important;
  width: 3px !important;
  top: 0px !important;
  right: 0px !important;
  left: 0px !important;
}

._ZFVon{
  width: 40px !important;
  height: 40px !important;
}

._2ZdKn{
  display: none !important;
}

._1-y3h {
  flex: 0;
}

._ZFVon svg{
  width: 25px !important;
  height: 25px !important;
}

.dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  color: inherit !important;
  padding: 0 !important;
}

.dropdown-toggle::after {
  display: none !important;
}

@media (min-width: 1200px) {
  .offset-right-xl-8 {
    margin-right: 66.66666667%;
  }

  .offset-right-xl-4 {
    margin-right: 33.33333333%;
  }

  .offset-right-xl-6 {
    margin-right: 50%;
  }

  .offset-right-xl-2 {
    margin-right: 16.66666667%;
  }
}

@media (min-width: 768px) {
  .offset-right-md-6 {
    margin-right: 50%;
  }
}

.DatePicker {
  width: 100%;
}

.DatePicker__input {
  height: 38px;
}

.Toastify {
  padding: 0 !important;
  margin: 0 !important;
  width: 0 !important;
  height: 0 !important;
}

iframe {
  width: 100%;
}

.field-icon-toggle {
  float: right;
  margin-top: -21px;
  position: relative;
  z-index: 2;
  margin-right: 10px;
}

.toggle-password {
  margin-top: -21px;
}

.img-avatar {
  display: flex;
  justify-content: center;
  color: white;
  cursor: pointer;
  width: 25px;
  margin: 10px;
}

.img-avatar:hover,
.img-avatar:focus{
  cursor: pointer;
}

textarea {
  padding: .5rem;
}

h1 svg {
  margin: 0 0.5rem 0 2rem;
  color: white;
}

a.disabled h1 svg {
  color: #9b9b9b;
}

.react-datepicker-popper,
.ReactModal__Overlay  {
  z-index: 1001 !important;
}

.css-t3ipsp-control,
.css-t3ipsp-control:hover,
.css-t3ipsp-control:focus {
  border-color: #00B3C0 !important;
  box-shadow: none !important;
}

.react-datalist-input__textbox {
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: .5rem;
  border: 1px solid #C4C4C4;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  outline: none;
  padding: 0.375rem 0.75rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width: 100%;
}

.react-datalist-input__label {
  display: none;
}

.react-datalist-input__listbox {
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #C4C4C4;
  color: #212529;
  position: static;
}

figure.figure,
.figure-video {
  position: relative;
  width: 100%;
  padding-bottom: 60%;
  height: 0;
}

figure.figure img,
.figure-video video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.flip{
  transform: rotateY(180deg) !important;
}

.table-responsive {
  /* Allowing dropdown actions to show, in TableRequest.js */
  overflow: auto !important;
  @media (min-width: 640px){
    overflow: visible !important;
  }
}

.MuiOtpInput-Box {
  gap:2px !important;
  @media (min-width: 640px){
    gap:20px !important;
  }
}

.MuiOtpInput-TextField,.MuiInputBase-root {
  font-size: 20px !important
}