
.SlideModal__Overlay {
  background-color: #0008;
  backdrop-filter: blur(2px);
  padding: 10px;
  box-sizing: border-box;
}

.SlideModal__Container {
  width: 650px;
  padding: 2rem;
  box-sizing: border-box;
  /* border-radius: 10px; */
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  box-shadow: 0px 3px 8px 2px #0008;
}

.SlideModal__Header {
  width: 100%;
  border-bottom: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.SlideModal__Close-btn {
  /* position: absolute; */
  width: 30px;
  height: 30px;
  /* top: 10px;
  right: 5px; */
  cursor: pointer;
  margin: 10px;
}

.SlideModal__Header h1 {
  color: var(--FEDERAL_BLACK_LIGHT);
  margin: 10px;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.Terms__Scroll-Container {
  width: 100%;
  height: 300px;
  position: relative;
}

.SlideModal__Scroll-area {
  height: inherit;
  overflow: scroll;
}
.SlideModal__Scroll-area::-webkit-scrollbar-thumb {
  background: #0005;
}

.SlideModal__Vanish {
  width: calc(100% - 10px);
  height: 60px;
  position: absolute;
  background: linear-gradient(0deg, #0000, #FFF);
  transition: all 1s;
  left: 0;
}

.SlideModal__VSH1 {
  top: 0;
}

.SlideModal__VSH2 {
  bottom: 0;
  transform: rotateX(180deg);
}

.SlideModal__Footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  min-height: 130px;
}

.SlideModal__Check-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SlideModal__CheckBox {
  margin-right: 5px;
}

.SlideModal__Button {
  width: 140px;
  height: 45px;
  border: none;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.2em;
}

.Slide__Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 20px !important;
}

.slideCaptchaContainer .panel {
  display: block !important;
  position: relative !important;
  bottom: 20px !important;
}

.Slide__Modal__Container {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.reset-button {
  background: transparent;
  border: none;
  cursor: pointer;
  align-self: center;
  margin: 10px;
  width: 30px;
  height: 30px;
}

.reset-button img {
  width: 30px;
  height: 30px;
}

.slideCaptchaContainer .control {
  margin: 0 2rem;
}

@media (max-width: 668px) {

  .slideCaptchaContainer .control {
    margin: 0;
  }

}

@media (max-width: 500px) {

  .SlideModal__Header h1 {
    font-size: 1.3em;
  }

  /* .SlideModal__Close-btn {
    width: 15px;
  } */

  .SlideModal__Footer p {
    font-size: .8em;
  }

}